.slider{
    position: absolute;
    left: 70px;
    top: 120px;
    width: 1349px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image{
    width: 1350px;
    height: 450px;
    border-radius: 10px;
  }
  .right-arrow{
      position: absolute;
      top: 50%;
      right: 32px;
      font-size: 3rem;
      color: black;
      z-index: 10;
      cursor: pointer;
      user-select: none;
  }
  .left-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: black;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .slide{
    opacity: 0;
    transition-duration: 1s ease;
  }
  
  .slide.active{
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  .slideleftright{
    display: block;
    text-align: center;
    background: rgb(255, 255, 255);
    z-index: 1;
    width: 30px;
    height: 80px;
    line-height: 100px;
    position: absolute;
    border-radius: 5px;
    left: 0px;
  }