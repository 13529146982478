/*
This is the extracted copied part responsible for showing & hiding the submenu.
*/
.nav__submenu {
    display: none;
  }
  
  .nav__menu-item:hover .nav__submenu {
    display: block;
  }
  
  /*
  Layout styles.
  
  I like to work on stuff that's good looking so I remixed a cool simple menu by Mike Rojas (thanks!): https://codepen.io/mikerojas87/pen/rojKb 
  */
  html {
    box-sizing: border-box;
  }
  
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  

  
  .menu-container {
    text-align: center;
  }
  
  nav ul {
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .nav {
    display: inline-block;
    background: #ffffff;
    color: rgb(8, 8, 8);
    text-align: left;
    left: 36px;
    position: relative;
  }
  .nav a {
    display: block;
    padding: 0 16px;
    line-height: inherit;
    cursor: pointer;
  }
  
  .nav__menu {
    line-height: 25px;
    font-weight: 700;
    }
  
  .nav__menu-item {
    padding-right: 44px;
    display: inline-block;
    position: relative;
    padding-top: 10px;
  }
  .nav__menu-item:hover {
    color: #f7550a;
  }
  .nav__menu-item:hover .nav__submenu {
    display: block;
    opacity: 1;
  }

  .menu-block {
    height: 45px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
    border-radius: 0 0 10px 10px;
    background: #fff;
    cursor:grab;
  }
  .nav__submenu{
    font-weight: 300;
    text-transform: none;
    display: none;
    position: absolute;
    width: 180px;
    z-index: 10;
    color:#000000;
    background-color: #e0e6e6;
  }
  

  .nav__submenu-item:hover {
    background: rgba(243, 241, 241, 0.8);
   }