.header {
    height:50px;
    padding-right: 500px;
    text-align: right;
    background: linear-gradient(180deg,#ff7631 0,#ff4b23);;
  }
  .header-img {
    position: absolute;
    margin-top: 2px;
    left: 80px;
    width: 240px;
    height: 39px;
    padding-top: 3px;
  }
  .header-search-text {
    border-color: white;
    font-size: 15px;
    text-indent: 25px; 
    font-size: 3;
    position: absolute;
    margin-top: 7px;
    left: 341px;
    width: 475px;
    height: 35px;
    padding-top: 10px;
    border-radius: 16px;
    }
  .header-search-button {
    border-color: white;
    position: absolute;
    margin-top: 9px;
    left: 767px;
    width: 50px;
    height: 33px;
    padding-top: 4px;
    border-radius: 16px;
    background-color: #e94a2700;
    border-color: #e94a2700;
  }
  .header-search-magnif{
  border-color: white;
  position: absolute;
  margin-top: 12px;
  left: 778px;
  width: 29px;
  height: 22px;
  padding-top: 4px;
  border-radius: 16px;
  background-color: white
}
.header-nav-top-const-text{
  position: absolute;
  margin-top: -1px;
  color: white;
  font-size: 13px;
  padding-top: 4px;;
}
.header-nav-top-const-delleft{
  left: 900px;
}
.header-nav-top-const-locleft{
  left: 1015px;
}
b {
  color: #ffffff;
}


.header-nav-top-const-svn{
  position: absolute;
  left: 0px;
  margin-top: 17px;
  width: 22px;
  height: 22px;
  color: white;
  font-size: 17px;
  padding-top: 4px;
}
.header-nav-top-const-locate{
  position: absolute;
  left: 1143px;
  color:white;
  font-size: 17px;
  padding-top: 4px;
}
.header-nav-top-const-locstore{
  position: absolute;
}
.header-nav-top-const-locsvn{
position: absolute;
margin-top: -1px;
color: white;
font-size: 13px;
padding-top: 4px;
width: 18px;
}
.header-nav-top-item-button{
  position: absolute;
  margin-top: 0px;
  font-size: 13px;
  padding-top: 0px;
  width: 35px;
  height: 25px;
  color: white;
  background-color: #ff4b23;
  border-color:#ff4b23;
  }
  .header-nav-top-item{
    position: absolute;
    margin-top: 3px;
    left: 76%;
    font-size: 13px;
    width: 57px;
    height: 19px;
    color: white;
    }
  .header-nav-top-item-button{
    position: absolute;
    margin-top: 21px;
    left: 77%;
    font-size: 13px;
    width: 37px;
    height: 21px;
    color: white;
    background-color: #e94a2700;
    border-color: #e94a2700;
    }
  .header-nav-top-item-svn{
      position: absolute;
      left:80%;
      margin-top: -1px;
      color: white;
      font-size: 13px;
      padding-top: 4px;
      width: 18px;
    }
    .header-nav-top-myaccount{
      position: absolute;
      left: 89%;
      margin-top: -1px;
      color: white;
      font-size: 13px;
      padding-top: 4px;
    }
    .header-nav-top-account-signin{
      position: absolute;
      margin-top: 21px;
      left: 89%;
      font-size: 13px;
      width: 62px;
      height: 21px;
      color: white;
      background-color: #e94a2700;
      border-color: #e94a2700;
      }
      .header-nav-top-account-signin-svg{
        position: absolute;
        left:93%;
        margin-top: -1px;
        color: white;
        font-size: 13px;
        padding-top: 4px;
        width: 18px;
      }